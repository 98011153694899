import { observer } from 'mobx-react-lite'
import Modal from '../shared/modal'
import { ReactComponent as UploadZen } from '../../assets/login/register-zen.svg'
import type { FileWithPath } from './upload/upload'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { selectStyles } from 'components/shared/select-styles'
import { useEffect, useState } from 'react'
import FileIcon from './upload/file-icon'
import { getExtension } from 'components/dataroom/upload/mime-types'
import type { SpaceFile } from 'stores/files/file'
import SmallLoader from 'components/shared/small-loader'
import { post } from 'core/services/http-service'

export interface DocumentType {
    uuid: string
    name: string
    category: string
}

export interface DocumentTypeChecklist {
    steps: {
        uuid: string
        name: string
        files: {
            uuid: string
            name: string
        }[]
    }[]
}

export type SpaceFileWithDocumentType = SpaceFile & { documentTypeUuid: string }

export const SpaceFileDocumentTypeModal = observer(
    ({
        onClose,
        onSaved,
        filesToMap,
        checklist,
    }: {
        onClose: () => void
        onSaved: (files: SpaceFileWithDocumentType[]) => void
        filesToMap: SpaceFile[]
        checklist: DocumentTypeChecklist
    }) => {
        const { t } = useTranslation()
        const [mappedFiles, setMappedFiles] = useState<SpaceFileWithDocumentType[]>([])
        const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
        const [loading, setLoading] = useState<boolean>(false)

        const saveFilesMapping = async (mappedFiles: SpaceFileWithDocumentType[]) => {
            setLoading(true)
            setSubmitDisabled(true)

            const postData = mappedFiles.map(({ uuid, documentTypeUuid }) => ({
                uuid,
                documentTypeUuid,
            }))
            await post<{ files: typeof postData }, void>('/v1/web/checklist/files-mapping', { files: postData })

            setLoading(false)
            onSaved(mappedFiles)
        }

        useEffect(() => {
            setSubmitDisabled(mappedFiles.length !== filesToMap.length)
        }, [mappedFiles, filesToMap])

        return (
            <Modal
                isOpen={true}
                size="1/2"
                onRequestClose={() => onClose()}
                overflowHidden={false}
                overlayClassName="z-50"
            >
                <UploadZen className="-mt-36" />

                {loading && (
                    <div className="absolute inset-0 flex items-center justify-center z-50 bg-white/50">
                        <div>
                            <SmallLoader />
                            <span>{t('web_checklist_saving_types')}</span>
                        </div>
                    </div>
                )}

                <div className="w-full flex flex-col gap-4 my-8 relative">
                    <div>
                        <p className="text-center">{t('web_checklist_required_document_types_help1')}</p>
                        <p className="text-center">{t('web_checklist_required_document_types_help2')}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        {filesToMap.map(file => {
                            return (
                                <div key={file.uuid} className="grid grid-cols-2 gap-2 items-center">
                                    <div className="flex gap-2 items-center">
                                        <FileIcon extension={getExtension(file.name)} className="w-5" />
                                        <span>{file.name}</span>
                                    </div>
                                    <Select<{ value: string; label: string }>
                                        styles={selectStyles}
                                        placeholder={t('web_checklist_document_type_placeholder_select')}
                                        options={checklist.steps.map(step => ({
                                            label: step.name,
                                            options: step.files.map(({ uuid, name }) => ({ value: uuid, label: name })),
                                        }))}
                                        onChange={value => {
                                            const mappedFile: SpaceFileWithDocumentType = {
                                                ...file,
                                                documentTypeUuid: value.value,
                                            }

                                            setMappedFiles([
                                                ...mappedFiles.filter(({ uuid }) => uuid !== mappedFile.uuid),
                                                mappedFile,
                                            ])
                                        }}
                                        /* options={documentTypes.map(({ uuid, name, category }) => ({
                                            value: uuid,
                                            label: `${category} - ${name}`,
                                        }))}
                                        onChange={value => {
                                            const mappedFile: SpaceFileWithDocumentType = {
                                                ...file,
                                                documentTypeUuid: value.value,
                                            }

                                            setMappedFiles([
                                                ...mappedFiles.filter(({ uuid }) => uuid !== mappedFile.uuid),
                                                mappedFile,
                                            ])
                                        }} */
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <button
                    type="button"
                    className="btn atomic-tangerine cursor-pointer text-sm font-bold"
                    disabled={submitDisabled}
                    onClick={async () => {
                        await saveFilesMapping(mappedFiles)
                    }}
                >
                    {t('web_checklist_confirm_mapping')}
                </button>
            </Modal>
        )
    }
)

// not used at the moment
export const DragDropFilesDocumentTypesModal = observer(
    ({
        onClose,
        onConfirm,
        documentTypes,
        filesToMap,
    }: {
        onClose: () => void
        onConfirm: (files: FileWithPath[]) => void
        documentTypes: DocumentType[]
        filesToMap: FileWithPath[]
    }) => {
        const { t } = useTranslation()
        const [mappedFiles, setMappedFiles] = useState<FileWithPath[]>([])

        return (
            <Modal
                isOpen={true}
                size="1/2"
                onRequestClose={() => onClose()}
                overflowHidden={false}
                overlayClassName="z-50"
            >
                <UploadZen className="-mt-36" />

                <div className="w-full flex flex-col gap-4 my-8">
                    <div>
                        <p className="text-center">{t('web_checklist_required_document_types_help1')}</p>
                        <p className="text-center">{t('web_checklist_required_document_types_help2')}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        {filesToMap.map(file => {
                            return (
                                <div key={file.fullPath} className="grid grid-cols-2 gap-2 items-center">
                                    <div className="flex gap-2 items-center">
                                        <FileIcon extension={getExtension(file.file.name)} className="w-5" />
                                        <span>{file.file.name}</span>
                                    </div>
                                    <Select
                                        styles={selectStyles}
                                        placeholder={t('web_checklist_document_type_placeholder_select')}
                                        options={documentTypes.map(({ uuid, name, category }) => ({
                                            value: uuid,
                                            label: `${category} - ${name}`,
                                        }))}
                                        onChange={value => {
                                            const mappedFile: FileWithPath = { ...file, documentTypeUuid: value.value }

                                            setMappedFiles([
                                                ...mappedFiles.filter(({ id }) => id !== mappedFile.id),
                                                mappedFile,
                                            ])
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <button
                    type="button"
                    className="btn atomic-tangerine cursor-pointer text-sm font-bold"
                    disabled={mappedFiles.length < filesToMap.length}
                    onClick={() => onConfirm(mappedFiles)}
                >
                    {t('web_checklist_confirm_mapping')}
                </button>
            </Modal>
        )
    }
)
