import { mapOrJsonOptions } from 'core/utils/map-to-object'
import { values } from 'mobx'
import { type Instance, applySnapshot, flow, getRoot, types } from 'mobx-state-tree'
import { _AnyJsonValue } from 'stores/any'
import { type User, _User } from 'stores/users'
import { get, post, put } from '../../core/services/http-service'
import toast from '../../core/utils/toast'
import type { RootInstance } from '../store'
import { type SpaceDirectory, _SpaceDirectory } from './directory'
import { _SpaceFile, type SpaceFile } from './file'

type Collaborator = 'ADMIN' | 'CONTROLLER' | 'AGENT' | 'FRANCHISEE'

type Display = 'menu' | 'dataroom' | 'collaboration'

interface SpaceOptions {
    displayName?: string
    kind?: string
    collaboration?: Collaborator[]
    displayOn?: { others?: Display; user?: Display }
    rh?: {
        filePattern?: string
    }
    secure_collaboration?: boolean
}

export const _ClientCustomField = types.model('ClientCustomField', {
    key: types.string,
    translationKey: types.string,
    type: types.enumeration(['text', 'textarea', 'email', 'number']),
    required: types.boolean,
    defaultValue: types.optional(types.string, '', [null, undefined]),
    help: types.optional(types.string, '', [null, undefined]),
})

export const _ClientCustomValue = types.model('ClientCustomValue', {
    key: types.string,
    value: types.string,
})

let _SpaceModel = types.model('Space', {
    uuid: types.optional(types.string, '', [null, undefined]),
    userUuid: types.optional(types.string, '', [null, undefined]),
    username: '',
    name: '',
    color: '',
    rootDirectory: types.optional(
        types.late(() => _SpaceDirectory),
        {},
        [null, undefined]
    ),
    users: types.array(types.late(() => _User)),
    invitations: types.array(types.string),
    spaces: types.map(types.late(() => _Space)),
    totalFiles: types.optional(types.number, -1, [null, undefined]),
    ecoResponsible: 0,
    toCompress: 0,
    size: 0,
    dateAdded: 0,
    lastModified: 0,
    deletedAt: types.optional(types.union(types.number, types.string), '', [null, undefined]),
    deletedBy: types.optional(types.string, '', [null, undefined]),
    isLoaded: false,
    isShared: false,
    collaborationId: types.optional(types.string, '', [null, undefined]),
    collaborationOwner: types.optional(
        types.late(() => _User),
        {},
        [null, undefined]
    ),
    trash: types.array(types.late(() => _SpaceFile)),
    collaborationManagers: types.array(types.string),
    shareLocked: types.optional(types.boolean, false, [null, undefined]),
    parent: types.optional(types.string, '', [null, undefined]),
    description: types.optional(types.string, '', [null, undefined]),
    isStack: false,
    isQuarantine: false,
    isTrash: false,
    isDeletable: true,
    isEditable: true,
    options: types.optional(_AnyJsonValue, {}, [null, undefined]),
    clientCustomFields: types.array(types.late(() => _ClientCustomField)),
    brandConfig: types.optional(_AnyJsonValue, {}, [null, undefined]),
})

_SpaceModel = _SpaceModel
    .views(self => ({
        get isDirectory() {
            return false
        },
        get isFile() {
            return false
        },
        get isSpace() {
            return true
        },
        get isConnector() {
            return false
        },
        get spaceUuid() {
            return self.uuid
        },
        get storeStatus() {
            return null
        },
        get jsonOptions(): SpaceOptions {
            return mapOrJsonOptions(self.options)
        },
        get parentUuid() {
            return self.parent
        },
        isInUserKind(stacks: string[]) {
            return stacks.some(kind => this.is(kind))
        },

        is(what: string) {
            switch (what) {
                case 'stack':
                    return self.isStack
                case 'trash':
                    return self.isTrash
                case 'quarantine':
                    return self.isQuarantine
                default: {
                    if (!this.jsonOptions || !this.jsonOptions.kind) {
                        return false
                    }

                    return this.jsonOptions.kind === what
                }
            }
        },

        get topSpace(): Space | undefined {
            const root = getRoot(self) as RootInstance
            const topSpace = root.files.recursivelyGetSpaceParent(self.uuid)

            return topSpace ?? self
        },

        get allDirectories(): SpaceDirectory[] {
            const directories: SpaceDirectory[] = []
            for (const directory of self.rootDirectory.directories) {
                directories.push(directory)
                directories.push(...directory.allDirectories)
            }

            return directories
        },

        get parentName(): string {
            if (self.parent === '') {
                return ''
            }

            const root = getRoot(self) as RootInstance
            const space = root.files.recursivelyGetSpaceParent(self.parent)

            return space?.displayName ?? ''
        },

        get allParents(): string[] {
            const root = getRoot(self) as RootInstance

            const uuids: string[] = [self.uuid]

            if (self.parent) {
                const space = root.files.recursivelyFindSpace(self.parent)
                if (space) {
                    uuids.push(...space.allParents)
                }
            }

            return uuids
        },

        findParentDirectory(parentUuid: string | undefined): SpaceDirectory | undefined {
            if (!parentUuid || parentUuid === '') {
                return undefined
            }

            return this.allDirectories.find(directory => directory.uuid === parentUuid)
        },

        get files(): SpaceFile[] {
            return [...values<SpaceFile>(self.rootDirectory.files)] ?? []
        },

        get directories(): SpaceFile[] {
            return [...values<SpaceDirectory>(self.rootDirectory.directories)] ?? []
        },

        get hasFile(): boolean {
            return self.totalFiles > 0
        },

        get displayName(): string {
            return self.name
        },

        displayNameForUser(user: User): string {
            if (self.userUuid !== user.uuid) {
                return `${self.username} - ${this.displayName}`
            }

            if (this.jsonOptions?.displayName) {
                return this.jsonOptions.displayName
            }

            return this.displayName
        },

        get isEmpty(): boolean {
            return self.uuid === ''
        },

        sizeInPercent(maxValue): number {
            return Math.round((self.size / maxValue) * 100 * 100) / 100
        },

        get ecoResponsiblePercent(): number {
            if (self.totalFiles === 0) {
                return 0
            }

            return Math.round((self.ecoResponsible / self.totalFiles) * 100 * 100) / 100
        },

        get toCompressPercent(): number {
            if (self.totalFiles === 0) {
                return 0
            }

            return Math.round((self.toCompress / self.totalFiles) * 100 * 100) / 100
        },

        get isOpen(): boolean {
            const root = getRoot(self) as RootInstance

            return root.config.isOpened(self.uuid)
        },
    }))
    .views(self => ({
        isEditableBy(user: User): boolean {
            if (!self.isEditable) {
                return false
            }

            const topSpace = this.topSpace
            if (!topSpace) {
                return false
            }

            if (topSpace.jsonOptions?.internalBrandDirectory) {
                return user.uuid === self.userUuid
            }

            return true
        },

        isDeletableBy(user: User): boolean {
            if (!self.isDeletable) {
                return false
            }

            const topSpace = this.topSpace
            if (!topSpace) {
                return false
            }

            if (topSpace.jsonOptions?.internalBrandDirectory) {
                return user.uuid === self.userUuid
            }

            return true
        },
    }))
    .actions(self => ({
        _reset: () => {
            applySnapshot(self, {})
        },

        _refresh(space) {
            applySnapshot(self, space)
        },
    }))
    .actions(self => ({
        open() {
            const root = getRoot(self) as RootInstance
            root.config.changeOpened(self, true)
        },

        close() {
            const root = getRoot(self) as RootInstance
            root.config.changeOpened(self, false)
        },

        markNotLoaded() {
            self.isLoaded = false
        },

        shouldOpen(
            highlightedSpace: string | undefined,
            highlightedDirectory: string | undefined,
            highlightedFile: string | undefined
        ) {
            if (!highlightedSpace && !highlightedDirectory && !highlightedFile) {
                return
            }

            const recursiveFind = (space: Space): boolean => {
                if (highlightedSpace === space.uuid) {
                    return true
                }

                for (const child of values<Space>(space.spaces)) {
                    if (recursiveFind(child)) {
                        child.open()

                        return true
                    }
                }

                for (const file of values<SpaceFile>(space.rootDirectory.files)) {
                    if (file.uuid === highlightedFile) {
                        return true
                    }
                }
                for (const child of values<SpaceDirectory>(space.rootDirectory.directories)) {
                    if (recursiveFind(child)) {
                        child.open()

                        return true
                    }
                }

                return false
            }

            if (recursiveFind(self)) {
                this.open()
            }
        },

        restoreFiles: async (files: SpaceFile[]) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    files: files.map(({ uuid }) => uuid),
                }
                await post<typeof postData, void>(`/v1/web/spaces/${self.uuid}/restore-files`, postData)

                const updatedData = await get<void, Space>(`/v1/web/spaces/${self.uuid}`)
                const {
                    data: { space },
                } = updatedData
                space.isLoaded = true
                self._refresh(space)
            } catch (err) {
                root.error.prepare(err)
            }
        },

        update: async (
            name: string | undefined,
            color: string | undefined,
            parentUuid: string | undefined,
            description: string = undefined,
            shareWith: string[] | undefined = undefined,
            isSecure: boolean | undefined = false
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonFile {
                uuid: string
                name: string
                hash: string
            }

            interface JsonDirectory {
                uuid: string
                name: string
                description: string
                files: JsonFile[]
                directories: JsonDirectory[]
            }

            interface JsonSpace {
                uuid: string
                name: string
                color: string
                size: number
                ecoResponsible: number
                toCompress: number
                directories: JsonDirectory[]
                parent: string
                isLoaded: boolean
                users: User[]
            }

            interface JsonSpaces {
                space: JsonSpace
                collaborationData?: object
            }

            interface JsonReturn {
                data: JsonSpaces
            }
            const postData = {
                name,
                color,
                parent: parentUuid,
                shareWith,
                description,
                isSecure,
            }
            try {
                const currentData = await get<void, JsonReturn>(`/v1/web/spaces/${self.uuid}`)
                const data = await put<typeof postData, JsonReturn>(`/v1/web/spaces/${self.uuid}`, postData)

                const {
                    data: { space: currentSpace },
                } = currentData

                const {
                    data: { space, collaborationData },
                } = data
                space.parent = parentUuid
                space.isLoaded = true
                self._refresh(space)

                if (collaborationData && space.name !== currentSpace.name) {
                    const notifyData = {
                        type: 'renamed_objects',
                        data: {
                            movedObjects: [
                                {
                                    object: {
                                        uuid: space.uuid,
                                        name: space.name,
                                        oldName: currentSpace.name,
                                        newName: space.name,
                                    },
                                    collaborationData,
                                },
                            ],
                        },
                    }
                    await post<typeof notifyData, void>('/v1/web/notifications/event', notifyData)
                }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        toggleSecure: async (secure: boolean) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = await put<{ secure: boolean }, { data: { space: typeof self } }>(
                    `/v1/web/spaces/${self.uuid}/secure`,
                    { secure }
                )

                const {
                    data: { space },
                } = data

                self._refresh({ ...self, options: space.options })

                return self.jsonOptions?.secure_collaboration
            } catch (err) {
                root.error.prepare(err)
            }
        },

        rename: flow(function* (name: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonFile {
                uuid: string
                name: string
                hash: string
            }

            interface JsonDirectory {
                uuid: string
                name: string
                description: string
                files: JsonFile[]
                directories: JsonDirectory[]
            }

            interface JsonSpace {
                uuid: string
                name: string
                color: string
                size: number
                ecoResponsible: number
                toCompress: number
                directories: JsonDirectory[]
            }

            interface JsonSpaces {
                space: JsonSpace
            }

            interface JsonReturn {
                data: JsonSpaces
            }
            const postData = {
                name,
            }

            try {
                const currentData = yield get<void, JsonReturn>(`/v1/web/spaces/${self.uuid}`)
                const {
                    data: { space: currentSpace },
                } = currentData

                const data = yield put<typeof postData, JsonReturn>(`/v1/web/spaces/${self.uuid}`, postData)

                const {
                    data: { space, collaborationData },
                } = data
                self.name = space.name

                if (collaborationData && space.name !== currentSpace.name) {
                    const notifyData = {
                        type: 'renamed_objects',
                        data: {
                            movedObjects: [
                                {
                                    object: {
                                        uuid: space.uuid,
                                        name: space.displayName,
                                        oldName: currentSpace.name,
                                        newName: space.name,
                                    },
                                    collaborationData,
                                },
                            ],
                        },
                    }
                    yield post<typeof notifyData, void>('/v1/web/notifications/event', notifyData)
                }
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        refresh: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonFile {
                uuid: string
                name: string
                hash: string
            }

            interface JsonDirectory {
                uuid: string
                name: string
                description: string
                files: JsonFile[]
                directories: JsonDirectory[]
            }

            interface JsonSpace {
                uuid: string
                name: string
                color: string
                size: number
                ecoResponsible: number
                toCompress: number
                directories: JsonDirectory[]
            }

            interface JsonSpaces {
                space: JsonSpace
            }

            interface JsonReturn {
                data: JsonSpaces
            }

            try {
                const data = yield get<void, JsonReturn>(`/v1/web/spaces/${self.uuid}`)

                const {
                    data: { space },
                } = data
                space.isLoaded = true
                self._refresh(space)
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))
    .actions(self => ({
        moveTo: flow(function* (space: Space | undefined) {
            yield self.update(self.name, self.color, space ? space.uuid : undefined)
            if (space) {
                yield space.refresh()
            }

            toast('success', 'web_spaceroom_moved', undefined, 'web_spaceroom_moved_message', undefined, {
                name: self.name,
            })
        }),

        getFileCount: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonFileCount {
                size: number
                totalFiles: number
                ecoResponsible: number
            }

            interface JsonReturn {
                data: JsonFileCount
            }

            try {
                const data = yield get<void, JsonReturn>(`/v1/web/spaces/${self.uuid}/files`)

                const {
                    data: { size, totalFiles, ecoResponsible },
                } = data

                self.size = size
                self.totalFiles = totalFiles
                self.ecoResponsible = ecoResponsible
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))

export const _Space = _SpaceModel
export interface Space extends Instance<typeof _Space> {}

export interface ClientCustomField extends Instance<typeof _ClientCustomField> {}
