import { DateTime } from 'luxon'
import { values } from 'mobx'
import { type Instance, destroy, flow, getRoot, types } from 'mobx-state-tree'
import { z } from 'zod'
import folder from '../assets/folder.svg'
import { del, get, post, put } from '../core/services/http-service'
import toast from '../core/utils/toast'
import type { ChecklistStep, ChecklistStepFile, Client } from './brands'
import { type SpaceDirectory, _SpaceDirectory } from './files/directory'
import { type GuestSharing, type SpaceFile, _SpaceFile } from './files/file'
import { type Meta, _Meta } from './files/meta'
import { type Space, _Space } from './files/space'
import type { FileStatus, FilenameSuggestion } from './files/suggestion'
import { _Partner } from './partners'
import { _ShareGroup } from './share-group'
import type { RootInstance } from './store'
import { _TrustedUser } from './trustedUser/trusted-user'
import { _User } from './users'

export const maxFileUploadCount = 2

export const _SharedUser = types.model('SharedUser', {
    uuid: '',
    user: types.optional(_TrustedUser, {}, [null, undefined]),
})
export interface SharedUser extends Instance<typeof _SharedUser> {}

export const _BackupFile = types
    .model('BackupFile', {
        status: types.optional(types.enumeration(['none', 'pending', 'running', 'done']), 'none', [null, undefined]),
        createdAt: types.optional(types.string, '', [undefined, null]),
        finishedAt: types.optional(types.string, '', [undefined, null]),
        file: types.optional(types.string, '', [undefined, null]),
    })
    .views(self => ({
        get validTo(): DateTime | undefined {
            if (!self.finishedAt || self.finishedAt === '') {
                return undefined
            }

            return DateTime.fromISO(self.finishedAt).plus({ days: 7 })
        },
    }))
export interface BackupFile extends Instance<typeof _BackupFile> {}

export const _SharedFile = types
    .model('SharedFile', {
        uuid: '',
        space: types.optional(
            types.late(() => _Space),
            {},
            [null, undefined]
        ),
        directory: types.optional(_SpaceDirectory, {}, [null, undefined]),
        file: types.optional(_SpaceFile, {}, [null, undefined]),
        public: false,
        users: types.array(_SharedUser),
        owner: types.optional(
            types.late(() => _User),
            {}
        ),
        partner: types.optional(
            types.late(() => _Partner),
            {}
        ),
        shareGroup: types.optional(_ShareGroup, {}, [null, undefined]),
        fromDate: '',
        toDate: types.optional(types.string, '', [null, undefined]),
        unlimited: false,
        downloaded: false,
        canBeRestarted: true,
    })
    .views(self => ({
        get isDirectory(): boolean {
            return self.directory.uuid !== '' && self.directory.isDirectory
        },
        get isFile(): boolean {
            return self.file.uuid !== '' && self.file.isFile
        },
        get isSpace(): boolean {
            return self.space.uuid !== '' && self.space.isSpace
        },
        get isConnector(): boolean {
            return false
        },

        get dateFrom(): DateTime {
            return DateTime.fromISO(self.fromDate)
        },
        get dateTo(): DateTime | undefined {
            if (self.toDate === '') {
                return undefined
            }

            return DateTime.fromISO(self.toDate)
        },
    }))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .views(self => ({
        get status(): 'running' | 'stopped' | 'expired' {
            if (this.dateTo && this.dateTo < DateTime.local()) {
                return 'expired'
            }

            return 'running'
        },
    }))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .views(self => ({
        get canDownload(): boolean {
            return this.status === 'running'
        },
    }))
export interface SharedFile extends Instance<typeof _SharedFile> {}

export const _SharedGroup = types
    .model('SharedGroup', {
        uuid: '',
        users: types.array(_SharedUser),
        shares: types.array(_SharedFile),
        shareGroup: types.optional(_ShareGroup, {}),
    })
    .views(self => ({
        get countShared(): number {
            return values(self.shares).length // + values(self.directories).length + values(self.files).length
        },
    }))
export interface SharedGroup extends Instance<typeof _SharedGroup> {}

export interface TrustedShareable {
    uuid: string
    unlimited: boolean
    fromDate: DateTime
    toDate?: DateTime
}
export interface TrustedShareableJson {
    uuid: string
    unlimited: boolean
    fromDate: string
    toDate?: string
}

export type SpaceOrDirectoryOrFile = SpaceFile | SpaceDirectory | Space

export interface DetachedSpaceOrDirectoryOrFile {
    uuid: string
    displayName: string
    isFile: boolean
    isDirectory: boolean
    isSpace: boolean
    isEditable: boolean
    isDeletable: boolean
    parentUuid: string

    connector?: string
    connectorUuid?: string
    subscriber?: string
    label?: string
    subConnector?: boolean

    storeStatus?: FileStatus | null

    downloadUrl?: string

    canRefresh?: boolean

    documentTypeRequired?: boolean

    object?: SpaceOrDirectoryOrFile
}

const _Widget = types.model('Widget', {
    part: types.array(types.string),
    pro: types.array(types.string),
})

export const _Files = types
    .model('Files', {
        spaces: types.map(types.late(() => _Space)),
        // suggestions: types.array(_FilenameSuggestion),
        meta: types.optional(_Meta, {}),
        trash: types.map(types.late(() => _SpaceFile)),
        colors: types.array(types.string),
        widgets: types.optional(_Widget, { pro: [], part: [] }, [null, undefined]),
        canUpload: true,
        ocrMimeTypes: types.array(types.string),
        reason: types.optional(types.string, '', [null, undefined]),

        sharedByMe: types.array(types.late(() => _SharedFile)),
        sharedWithMe: types.array(types.late(() => _SharedFile)),

        sharedGroupByMe: types.array(_SharedGroup),
        sharedGroupWithMe: types.array(_SharedGroup),

        backup: types.optional(_BackupFile, {}, [null, undefined]),
    })
    .volatile<{
        ocrFileRenamed: { uuid: string; name: string } | undefined
        ocrFileMovedTo: string | undefined
        lastUpdate: DateTime
        lastConfigFetch: DateTime
        isUploading: boolean
        askForRule: string[]
        forceRefresh: string[] | undefined
    }>(() => ({
        lastUpdate: DateTime.local().minus({ days: 1 }),
        lastConfigFetch: DateTime.local().minus({ days: 1 }),
        ocrFileMovedTo: undefined,
        ocrFileRenamed: undefined,
        isUploading: false,
        askForRule: [],
        forceRefresh: [],
    }))
    .views(self => ({
        recursivelyFindFile(uuid: string): SpaceFile | undefined {
            const _recursivelyFindFile = (space: Space, directory: SpaceDirectory, uuid: string): Space | undefined => {
                const files = values<SpaceFile>(directory.files)
                for (const file of files) {
                    if (file.uuid === uuid) {
                        return file
                    }
                }

                const directories = values<SpaceDirectory>(directory.directories)
                for (const child of directories) {
                    const found = _recursivelyFindFile(space, child, uuid)
                    if (found) {
                        return found
                    }
                }

                const children = values<Space>(space.spaces)
                for (const child of children) {
                    const found = _recursivelyFindFile(child, child.rootDirectory, uuid)
                    if (found) {
                        return found
                    }
                }

                return undefined
            }

            const spaces = values<Space>(self.spaces)
            for (const space of spaces) {
                const found = _recursivelyFindFile(space, space.rootDirectory, uuid)
                if (found) {
                    return found
                }
            }

            return undefined
        },

        recursivelyFindDirectory(uuid: string, fromSpace: Space = undefined): SpaceDirectory | undefined {
            const _recursivelyFindDirectory = (
                space: Space,
                directory: SpaceDirectory,
                uuid: string
            ): Space | undefined => {
                if (directory.uuid === uuid) {
                    return directory
                }

                const directories = values<SpaceDirectory>(directory.directories)
                for (const child of directories) {
                    const found = _recursivelyFindDirectory(space, child, uuid)
                    if (found) {
                        return found
                    }
                }

                const children = values<Space>(space.spaces)
                for (const child of children) {
                    const found = _recursivelyFindDirectory(child, child.rootDirectory, uuid)
                    if (found) {
                        return found
                    }
                }

                return undefined
            }

            if (fromSpace) {
                return _recursivelyFindDirectory(fromSpace, fromSpace.rootDirectory, uuid)
            }

            const spaces = values<Space>(self.spaces)
            for (const space of spaces) {
                const found = _recursivelyFindDirectory(space, space.rootDirectory, uuid)
                if (found) {
                    return found
                }
            }

            return undefined
        },

        recursivelyFindSpace(uuid: string): Space | undefined {
            const _recursivelyFindSpace = (space: Space, uuid: string): Space | undefined => {
                if (space.uuid === uuid) {
                    return space
                }

                const children = values<Space>(space.spaces)
                for (const child of children) {
                    const found = _recursivelyFindSpace(child, uuid)
                    if (found) {
                        return found
                    }
                }

                return undefined
            }

            const spaces = values<Space>(self.spaces)
            for (const space of spaces) {
                const found = _recursivelyFindSpace(space, uuid)
                if (found) {
                    return found
                }
            }

            return undefined
        },

        recursivelyGetSpaceParent(uuid: string): Space | undefined {
            const _recursivelyFindSpace = (space: Space, uuid: string): boolean => {
                if (space.uuid === uuid) {
                    return true
                }

                const children = values<Space>(space.spaces)
                for (const child of children) {
                    const found = _recursivelyFindSpace(child, uuid)
                    if (found) {
                        return true
                    }
                }

                return false
            }

            const spaces = values<Space>(self.spaces)
            for (const space of spaces) {
                const found = _recursivelyFindSpace(space, uuid)
                if (found) {
                    return space
                }
            }

            return undefined
        },
    }))
    .actions(self => ({
        clearForceRefresh(uuid: string) {
            self.forceRefresh = self.forceRefresh.filter(f => f !== uuid)
        },

        shouldForceRefresh(uuid: string | undefined): boolean {
            return self.forceRefresh?.includes(uuid) ?? false
        },

        markForceRefresh(uuid: string | string[]) {
            self.forceRefresh = Array.isArray(uuid) ? uuid : [uuid]
        },

        setAskForRule(askForRule: string[]) {
            self.askForRule = askForRule
        },

        assignSpaces(spaces: Space[], forceRefresh: boolean) {
            for (const space of spaces) {
                if (self.spaces.has(space.uuid) && self.spaces.get(space.uuid).isLoaded && !forceRefresh) {
                    continue
                }
                self.spaces.set(space.uuid, space)
            }
        },
    }))
    .actions(self => ({
        setUploading(value: boolean) {
            self.isUploading = value
        },
        setOcrFileRenamed(value: { uuid: string; name: string } | undefined) {
            self.ocrFileRenamed = value
        },
        setOcrFileMovedTo(value: string | undefined) {
            self.ocrFileMovedTo = value
        },

        getFilenameSuggestions: async (file: SpaceFile) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = await get<void, { data: { suggestion: FilenameSuggestion } }>(
                    `/v1/web/filenames/${file.uuid}`
                )

                const {
                    data: { suggestion },
                } = data

                file.setSuggestion(suggestion)
            } catch (err) {
                root.error.prepare(err)
            }
        },

        getFilesByHash(hash: string): Record<string, number> {
            let files: Record<string, number> = {}

            const _recursivelyFilesHashes = (space: Space, directory: SpaceDirectory): Record<string, number> => {
                let allHashes: Record<string, number> = {}

                const directories = values<SpaceDirectory>(directory.directories)
                for (const child of directories) {
                    const hashes = _recursivelyFilesHashes(space, child)
                    allHashes = {
                        ...allHashes,
                        ...hashes,
                    }
                }

                const children = values<Space>(space.spaces)
                for (const child of children) {
                    const hashes = _recursivelyFilesHashes(child, child.rootDirectory)
                    allHashes = {
                        ...allHashes,
                        ...hashes,
                    }
                }

                const files = values<SpaceFile>(directory.files)
                for (const file of files) {
                    if (file.hash === hash) {
                        allHashes[file.uuid] = file.dbCreatedAt
                    }
                }

                return allHashes
            }

            for (const space of values<Space>(self.spaces)) {
                const hashes = _recursivelyFilesHashes(space, space.rootDirectory)
                files = {
                    ...files,
                    ...hashes,
                }
            }

            return files
        },

        createSpace: async (
            name: string,
            color: string,
            parent: Space | undefined,
            description: string | undefined = undefined,
            shareWith: string[] | undefined = undefined,
            isSecure: boolean | undefined = false
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonSpace {
                uuid: string
                parentUuid: string | null
                name: string
                color: string
                size: number
                ecoResponsible: number
                toCompress: number
                directories: string[]
            }

            interface JsonSpaces {
                space: JsonSpace
            }

            interface JsonReturn {
                data: JsonSpaces
            }

            const postData = {
                name,
                color,
                parent: parent ? parent.uuid : undefined,
                shareWith,
                description,
                isSecure,
            }

            try {
                const data = await post<typeof postData, JsonReturn>('/v1/web/spaces', postData)
                const {
                    data: { space },
                } = data
                if (space.parentUuid === null) {
                    self.spaces.set(space.uuid, space)
                }

                toast('success', 'web_create_space_created')

                return space
            } catch (err) {
                root.error.prepare(err)

                return undefined
            }
        },

        getSpaceFilesCount: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield get<void, void>('/v1/web/spaces/files')
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        updateDirectory: async (
            id: string,
            name: string,
            description: string,
            spaceUuid: string,
            parentUuid: string,
            createUserDirectory: undefined | boolean = false,
            clientCustomValues?: Record<string, unknown>,
            brandConfig?: Record<string, unknown>
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonSpace {
                uuid: string
                name: string
                color: string
                size: number
                ecoResponsible: number
                toCompress: number
                directories: string[]
            }

            interface JsonSpaces {
                space: JsonSpace
            }

            interface JsonReturn {
                data: JsonSpaces
            }

            try {
                const postData = {
                    name,
                    description,
                    space: spaceUuid,
                    parent: parentUuid,
                    createUserDirectory,
                    clientCustomValues,
                }
                await put<typeof postData, JsonReturn>(`/v1/web/directories/${id}/update`, postData)

                toast(
                    'success',
                    createUserDirectory
                        ? brandConfig?.client_label
                            ? 'web_update_user_directory_updated_custom'
                            : 'web_update_user_directory_updated'
                        : 'web_update_directory_updated',
                    undefined,
                    undefined,
                    brandConfig?.client_label ? { client_label: brandConfig?.client_label as string } : undefined
                )
            } catch (err) {
                root.error.prepare(err)
            }
        },

        createDirectory: async (
            name: string,
            description: string,
            spaceUuid: string,
            parentUuid: string,
            createUserDirectory: undefined | boolean = false,
            clientCustomValues?: Record<string, unknown>,
            brandConfig?: Record<string, unknown>
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonSpace {
                uuid: string
                name: string
                color: string
                size: number
                ecoResponsible: number
                toCompress: number
                directories: string[]
            }

            interface JsonSpaces {
                space: JsonSpace
            }

            interface JsonReturn {
                data: JsonSpaces
            }

            try {
                const postData = {
                    name,
                    description,
                    space: spaceUuid,
                    parent: parentUuid,
                    createUserDirectory,
                    clientCustomValues,
                }
                await post<typeof postData, JsonReturn>('/v1/web/directories', postData)

                toast(
                    'success',
                    createUserDirectory
                        ? brandConfig?.client_label
                            ? 'web_create_user_directory_created_custom'
                            : 'web_create_user_directory_created'
                        : 'web_create_directory_created',
                    undefined,
                    undefined,
                    brandConfig?.client_label ? { client_label: brandConfig?.client_label as string } : undefined
                )
            } catch (err) {
                root.error.prepare(err)
            }
        },

        getConfig: flow(function* () {
            if (Math.abs(self.lastConfigFetch.diffNow('seconds').seconds) < 10) {
                return
            }
            self.lastConfigFetch = DateTime.local()
            const root = getRoot(self) as RootInstance
            root.error.clean()
            interface JsonReturnContent {
                meta: Meta
                colors: string[]
                ocrMimeTypes: string[]
            }
            interface JsonReturn {
                data: JsonReturnContent
            }
            try {
                const data = yield get<void, JsonReturn>('/v1/web/files/config')
                const {
                    data: { meta, colors, widgets, reason, canUpload, ocrMimeTypes },
                } = data
                self.meta = meta
                self.colors = colors
                self.widgets = widgets
                self.reason = reason
                self.canUpload = canUpload
                self.ocrMimeTypes = ocrMimeTypes
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        loadTrashedFiles: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonFiles {
                name: string
            }

            interface JsonReturn {
                data: JsonFiles
            }

            try {
                const data = yield get<void, JsonReturn>('/v1/web/trash')

                const {
                    data: { files },
                } = data
                for (const uuid of Object.keys(files)) {
                    const file = files[uuid]
                    self.trash.set(uuid, file)
                }
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        canOcr: flow(function* (name: string) {
            try {
                const data = yield get<{ ext: string }, { canOcr: boolean }>('/v1/web/ocr', { ext: name })

                const {
                    data: { canOcr },
                } = data

                return canOcr
            } catch (err) {
                return false
            }
        }),

        getFiles: async (forceRefresh: boolean | undefined = false) => {
            const { seconds } = DateTime.local().diff(self.lastUpdate, 'seconds')
            if (seconds < 10 && !forceRefresh) {
                return
            }
            self.lastUpdate = DateTime.local()

            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonFile {
                uuid: string
                name: string
                hash: string
            }

            interface JsonDirectory {
                uuid: string
                name: string
                description: string
                files: JsonFile[]
                directories: JsonDirectory[]
            }

            interface JsonSpace {
                uuid: string
                name: string
                color: string
                size: number
                ecoResponsible: number
                toCompress: number
                directories: JsonDirectory[]
            }

            interface JsonSpaces {
                spaces: JsonSpace
            }

            interface JsonReturn {
                data: JsonSpaces
            }

            try {
                const data = await get<void, JsonReturn>('/v1/web/spaces')
                const {
                    data: { spaces },
                } = data

                self.assignSpaces(spaces as unknown as Space[], forceRefresh)
            } catch (err) {
                root.error.prepare(err)
            }
        },

        delete: async (filesOrDirectories: DetachedSpaceOrDirectoryOrFile[]) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            type SuggestionOrSpaceOrDirectoryOrFile = SpaceOrDirectoryOrFile
            const toBeDestroyed: SuggestionOrSpaceOrDirectoryOrFile[] = []

            const deletedObjects: {
                fileUuid?: string
                directoryUuid?: string
                spaceUuid?: string
                name: string
            }[] = []

            for (const fileOrDirectory of filesOrDirectories) {
                if (fileOrDirectory.uuid === '') {
                    continue
                }
                if (!fileOrDirectory.isDeletable) {
                    continue
                }

                let url: string | undefined = undefined
                if (fileOrDirectory.isDirectory) {
                    url = `/v1/web/directories/${fileOrDirectory.uuid}`
                    const directory = self.recursivelyFindDirectory(fileOrDirectory.uuid)
                    if (directory) {
                        deletedObjects.push({
                            directoryUuid: fileOrDirectory.uuid,
                            name: fileOrDirectory.displayName,
                        })

                        toBeDestroyed.push(directory)
                    }
                } else if (fileOrDirectory.isSpace) {
                    url = `/v1/web/spaces/${fileOrDirectory.uuid}`
                    const space = self.recursivelyFindSpace(fileOrDirectory.uuid)
                    if (space) {
                        deletedObjects.push({
                            spaceUuid: fileOrDirectory.uuid,
                            name: fileOrDirectory.displayName,
                        })

                        toBeDestroyed.push(space)
                    }
                } else if (fileOrDirectory.isFile) {
                    url = `/v1/web/files/${fileOrDirectory.uuid}`
                    const file = self.recursivelyFindFile(fileOrDirectory.uuid)
                    if (file) {
                        deletedObjects.push({
                            fileUuid: fileOrDirectory.uuid,
                            name: fileOrDirectory.displayName,
                        })

                        toBeDestroyed.push(file)
                    }
                }

                if (!url) {
                    continue
                }

                try {
                    await del<void>(url)
                } catch (err) {
                    root.error.prepare(err)
                }
            }

            for (const node of toBeDestroyed) {
                try {
                    destroy(node)
                } catch (err) {
                    // ignore
                }
            }

            if (deletedObjects.length > 0) {
                const notifyData = {
                    type: 'deleted_objects',
                    data: { deletedObjects },
                }
                await post<typeof notifyData, void>('/v1/web/notifications/event', notifyData)
            }

            toast('warning', 'web_dataroom_file_move_to_trash', folder, undefined, { count: filesOrDirectories.length })
        },

        restore: flow(function* (files: SpaceFile[]) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            type JsonFile = object
            interface JsonReturn {
                data: JsonFile
            }
            type JsonAny = object

            const trash = self.trash
            for (const file of files) {
                try {
                    yield post<JsonAny, JsonReturn>(`/v1/web/trash/${file.uuid}`, {})
                } catch (err) {
                    root.error.prepare(err)
                }
                trash.delete(file.uuid)
            }
            self.trash.replace(trash)

            toast('warning', 'web_dataroom_file_restored', folder, undefined, { count: files.length })
        }),

        confirmDelete: flow(function* (files: SpaceFile[]) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const trash = self.trash
            for (const file of files) {
                try {
                    yield del<void>(`/v1/web/trash/${file.uuid}`)
                } catch (err) {
                    root.error.prepare(err)
                }
                trash.delete(file.uuid)
            }
            self.trash.replace(trash)

            toast('warning', 'web_dataroom_file_deleted', folder, undefined, { count: files.length })
        }),

        emptyTrash: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield del<void>('/v1/web/trash')
                self.trash.replace([])

                toast('warning', 'web_dataroom_trash_emptied', folder)
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        moveFiles: async (
            objects: SpaceOrDirectoryOrFile[],
            space: Space | undefined,
            directory: SpaceDirectory | undefined,
            action: string,
            type: 'move' | 'copy' = 'move',
            showNotification = true
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface File {
                spaceUuid: string
                directoryUuid: string
                action?: string
            }
            interface Directory {
                space: string
                parent: string
            }
            interface Space {
                parent: string
                removeSpace: boolean
            }

            const toRefresh: SpaceOrDirectoryOrFile[] = []
            const askForRule: string[] = []
            let errors = 0
            let success = 0

            const uuid = z.string().uuid()

            type FileDirectorySpace = File | Directory | Space
            type PostData =
                | (FileDirectorySpace & {
                      type: typeof type
                      directoryName?: string | undefined
                      spaceName?: string | undefined
                  })
                | null

            interface CollaborationData {
                uuid: string
                type: string
                action?: string
                file?: { uuid: string; name: string; oldName?: string; newName?: string }
                directory?: { uuid: string; name: string }
                location: {
                    topSpace: { uuid: string; name: string }
                    space?: { uuid: string; name: string }
                    directory?: { uuid: string; name: string }
                }
            }

            const movedObjects: {
                object: {
                    uuid: string
                    name: string
                }
                collaborationData: CollaborationData
            }[] = []

            for (const object of objects) {
                let url: string | undefined = undefined

                let postData: PostData = null

                if (object.isFile) {
                    if (!space) {
                        continue
                    }

                    url = `/v1/web/files/${object.uuid}`
                    postData = {
                        type,
                        spaceUuid: space.uuid,
                        spaceName: space.name,
                        directoryUuid: directory?.uuid ?? space.rootDirectory.uuid,
                        directoryName: directory?.name ?? space.rootDirectory.name,
                        action,
                    }
                } else if (object.isDirectory) {
                    if (!space) {
                        continue
                    }

                    url = `/v1/web/directories/${object.uuid}`
                    postData = {
                        type,
                        space: space.uuid,
                        parent: directory?.uuid ?? space.rootDirectory.uuid,
                    }
                } else if (object.isSpace) {
                    url = `/v1/web/spaces/${object.uuid}`
                    postData = {
                        type,
                        parent: space?.uuid,
                        removeSpace: !space,
                    }
                }

                if (space) {
                    const directoryObject = self.recursivelyFindDirectory(directory?.uuid ?? space.rootDirectory.uuid)
                    if (directoryObject) {
                        toRefresh.push(directoryObject)
                    }
                    const spaceObject = self.recursivelyFindSpace(space.uuid)
                    if (spaceObject) {
                        toRefresh.push(spaceObject)
                    }
                }

                if (url === undefined) {
                    continue
                }

                try {
                    const data = await put<typeof postData, { data: any }>(url, postData)
                    const {
                        data: { rule, collaborationData },
                    } = data

                    if (rule) {
                        askForRule.push(object.uuid)
                    }

                    if (collaborationData) {
                        movedObjects.push({
                            object: {
                                uuid: object.uuid,
                                name: object.displayName,
                            },
                            collaborationData,
                        })
                    }

                    success += 1
                } catch (err) {
                    // root.error.prepare(err)
                    if (err.code === 400 && err.message === 'api_filename_already_exists') {
                        throw err
                    }
                    errors += 1
                }
            }

            if (movedObjects.length > 0) {
                const notifyData = {
                    type: 'moved_objects',
                    data: { movedObjects },
                }
                await post<typeof notifyData, void>('/v1/web/notifications/event', notifyData)
            }

            if (showNotification || errors > 0) {
                const message =
                    errors === objects.length
                        ? 'web_dataroom_file_errored'
                        : errors > 0
                          ? 'web_dataroom_file_saved_with_errors'
                          : 'web_dataroom_file_saved'
                toast('success', message, folder, undefined, {
                    count: success,
                })
            }
            self.setAskForRule(askForRule)
        },

        clearRules() {
            self.askForRule = []
        },

        askOcr: async (fileId: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = await get<void, { data: { ocrAsked: boolean } }>(`/v1/web/files/${fileId}/ocr`)

                const {
                    data: { ocrAsked },
                } = data

                return ocrAsked
            } catch (err) {
                root.error.prepare(err)
            }
        },

        keepRules: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            for (const directory of self.askForRule) {
                try {
                    const data = { directory }
                    yield post<typeof data, void>('/v1/web/rules', data)
                } catch (err) {
                    root.error.prepare(err)
                }
            }

            toast('success', 'web_rules_saved', folder, undefined, { count: self.askForRule.length })
            self.setAskForRule([])
        }),

        moveFilesFromTrash: flow(function* (files: SpaceOrDirectoryOrFile[], space: Space, directory: SpaceDirectory) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface JsonType {
                spaceUuid: string
                directoryUuid: string
            }

            const trash = self.trash
            for (const file of files) {
                try {
                    yield put<JsonType, void>(`/v1/web/files/${file.uuid}`, {
                        spaceUuid: space.uuid,
                        directoryUuid: directory.uuid,
                    })
                } catch (err) {
                    root.error.prepare(err)
                }

                trash.delete(file.uuid)
            }
            self.trash.replace(trash)
            toast('success', 'web_dataroom_file_saved', folder, undefined, { count: files.length })
        }),

        share: flow(function* (
            objects: DetachedSpaceOrDirectoryOrFile[],
            users: TrustedShareable[],
            isGroup: boolean,
            groupName?: string
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            interface Data {
                users: TrustedShareableJson[]
                file?: string
                directory?: string
                space?: string
                isGroup: boolean
                groupName?: string
            }

            for (const object of objects) {
                try {
                    let postData: Data = {
                        isGroup,
                        groupName,
                        users: users.map(user => ({
                            ...user,
                            fromDate: user.fromDate.toISO(),
                            toDate: user.toDate?.toISO(),
                        })),
                    }

                    if (object.isFile) {
                        postData = {
                            ...postData,
                            file: object.uuid,
                        }
                    } else if (object.isDirectory) {
                        postData = {
                            ...postData,
                            directory: object.uuid,
                        }
                    } else if (object.isSpace) {
                        postData = {
                            ...postData,
                            space: object.uuid,
                        }
                    }

                    yield post<typeof postData, void>('/v1/web/share', postData)
                } catch (err) {
                    root.error.prepare(err)

                    return false
                }
            }

            return true
        }),

        getFilesSharedByMe: flow(function* (groupPeople = false) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const getData = { group: groupPeople }
                const {
                    data: { files },
                } = yield get<typeof getData, void>('/v1/web/shared-by', getData)
                if (groupPeople) {
                    self.sharedGroupByMe = files
                } else {
                    self.sharedByMe = files
                }
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        getFilesSharedWithMe: flow(function* (groupPeople = false) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const getData = { group: groupPeople }
                const {
                    data: { files },
                } = yield get<typeof getData, void>('/v1/web/shared-with', getData)
                if (groupPeople) {
                    self.sharedGroupWithMe = files
                } else {
                    self.sharedWithMe = files
                }
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))
    .actions(self => ({
        downloadShare: flow(function* (uuid: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield post<void, void>(`/v1/web/copy-share/${uuid}`)
                toast('success', 'web_share_copied')
                yield self.getFilesSharedWithMe(false)
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        stopAllSharing: flow(function* (groupPeople = false) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield del<void>('/v1/web/share')
                yield self.getFilesSharedByMe(groupPeople)
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        checkForBackup: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { backup },
                } = yield get<void, { data: { backup: BackupFile } }>('/v1/web/backup')
                self.backup = backup
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        postBackup: flow(function* () {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield post<void, void>('/v1/web/backup')
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        stopSharing: flow(function* (uuid: string, deleteShare: boolean, groupPeople: boolean) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                yield del<void>(`/v1/web/share/${uuid}?delete=${deleteShare ? 'delete' : ''}`)
                yield self.getFilesSharedByMe(groupPeople)
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        restartSharing: flow(function* (
            uuid: string,
            fromDate: DateTime,
            toDate: DateTime | undefined,
            unlimited: boolean,
            groupPeople = false
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const putData = {
                    fromDate: fromDate.toISO(),
                    toDate: toDate?.toISO(),
                    unlimited,
                }
                yield put<typeof putData, void>(`/v1/web/share/${uuid}`, putData)
                yield self.getFilesSharedByMe(groupPeople)
            } catch (err) {
                root.error.prepare(err)
            }
        }),

        getChecklist: async (directoryUuid: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { client },
                } = await get<never, { data: { client: Client } }>(`/v1/web/checklist/${directoryUuid}`)

                return client
            } catch (err) {
                root.error.prepare(err)
            }
        },

        toggleChecklistFile: async (file: ChecklistStepFile, what: 'concerned' | 'validated') => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { file: newFile, completion, totalCompletion, fileChoiceRequired },
                } = await put<
                    never,
                    {
                        data: {
                            file: ChecklistStepFile
                            completion: number
                            totalCompletion: number
                            fileChoiceRequired: boolean
                        }
                    }
                >(`/v1/web/checklist/toggle/${file.uuid}/${what}`)

                return { file: newFile, completion, totalCompletion, fileChoiceRequired }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        saveFileDate: async (file: ChecklistStepFile, date: Date) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const postData = {
                date: DateTime.fromJSDate(date).toISO(),
            }

            try {
                const {
                    data: { file: newFile, completion, totalCompletion },
                } = await put<
                    typeof postData,
                    { data: { file: ChecklistStepFile; completion: number; totalCompletion: number } }
                >(`/v1/web/checklist/date/${file.uuid}`, postData)

                return { file: newFile, completion, totalCompletion }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        addChecklistStepLine: async (stepUuid, lineValue, lineId) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { step, client },
                } = await post<{ line: string; id: string }, { data: { step: ChecklistStep; client: Client } }>(
                    `/v1/web/checklist/step/${stepUuid}/line`,
                    { line: lineValue, id: lineId }
                )

                return { step, client }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        deleteChecklistStepLine: async (stepUuid, lineId) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { client },
                } = await post<never, { data: { client: Client } }>(`/v1/web/checklist/step/${stepUuid}/line/${lineId}`)

                return { client }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        generatePdf: async (uuid: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { pdf },
                } = await post<void, { data: { pdf: string } }>(`/v1/web/checklist/${uuid}/pdf`)

                return pdf
            } catch (err) {
                root.error.prepare(err)
            }
        },

        getSharableLink: async (
            files: DetachedSpaceOrDirectoryOrFile[],
            archiveName: string,
            keepStructure: boolean
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    archiveName,
                    keepStructure,
                    files: files.map(file => ({
                        uuid: file.uuid,
                        type: file.isSpace ? 'space' : file.isDirectory ? 'directory' : 'file',
                    })),
                }

                const {
                    data: { url },
                } = await post<typeof postData, { data: { url: string } }>('/v1/web/files/shares', postData)

                return url
            } catch (err) {
                root.error.prepare(err)
            }
        },

        getShare: async (shareId: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { share },
                } = await get<never, { data: { share: GuestSharing } }>(`/v1/web/share/${shareId}`)

                return share
            } catch (err) {
                // root.error.prepare(err)
            }
        },

        transferFiles: async (
            files: DetachedSpaceOrDirectoryOrFile[],
            destinationUuid: string,
            destinationType: 'directory' | 'space',
            userUuid: string
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    files: files.map(file => ({
                        uuid: file.uuid,
                        type: file.isSpace ? 'space' : file.isDirectory ? 'directory' : 'file',
                    })),
                    user: userUuid,
                    destinationUuid,
                    destinationType,
                }

                const {
                    data: { space },
                } = await post<typeof postData, { data: { space: string } }>('/v1/web/files/transfer', postData)

                return space
            } catch (err) {
                root.error.prepare(err)
            }
        },
    }))

export interface Files extends Instance<typeof _Files> {}
